export default {
  seekersList: {
    header: {
      title: "Client List",
      counter: "Clients",
    },
    list: {
      displayName: "Organisation Name",
      country: "Country",
      leadName: "Lead name",
      leadEmail: "Lead email",
    },
  },
  investeeList: {
    header: {
      title: "Project List",
      counter: "Project",
    },
  },
  investorList: {
    header: {
      title: "Investors List",
      counter: "Investors",
    },
  },
  matchList: {
    header: {
      title: "Investor Interests List",
      counter: "interests",
    },
  },
  dashboard: {
    charts: {
      match: { title: "Investment Activity", subtitle: "Matched deals" },
      investor: { title: "Investors", subtitle: "Onboarding progress" },
      investee: { title: "Projects", subtitle: "Projects Progress" },
    },
  },
  investeeDetail: {
    header: {
      backButton: "Back to projects",
      role: "Project",
    },
  },
  investorDetail: {
    potentialDeals: {
      title: "Water projects ready for funding",
      subtitle: "Projects",
      table: {
        companyName: "Project Name",
        country: "Country",
        sector: "Water and sanitation sub-sectors",
        leadEmail: "Contact Person email",
        action: "Action",
      },
    },
  },
  seekerDetail: {
    header: {
      backButton: "Back to clients",
      role: "Client",
    },
  },
  reassign: {
    header: "Proposal reassignment",
    search: {
      title: "Search Client List",
      p1: "Reassign this project to a new client account by transferring access to its details on the public portal. Once reassigned, the previous account will lose access to the project and any associated details they had provided.",
      p2: "Use the search functionality below to find and select the desired client account. Note that only registered accounts can be assigned project records.",
      placeholder: "Search by Client Email",
      select: "Select Client account",
    },
    popup: {
      message:
        "Are you sure you want to reassign this project record? This action will transfer ownership of this project ownership to ",
    },
  },
};
